import { Html, useProgress } from "@react-three/drei"


const CustomLoader = () => {
    const { progress } = useProgress()
    return (
        <Html center>
            <span style={{ color: 'white' }}>{progress} % loaded</span>
        </Html>
    )
}

export default CustomLoader;