import * as React from "react";
import {  ChakraProvider, Stack, extendTheme } from "@chakra-ui/react";
import PlasticBagViewer from "./components/PlasticBagViewer";
import { hot } from "react-hot-loader/root";
import "./index.css";

const theme = extendTheme({
  colors: {
    brand: {
      100: "#f7fafc",
      // ...
      900: "#1a202c",
    },
  },
});

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Stack width="100%" height="100vh">
        <PlasticBagViewer />
      </Stack>
    </ChakraProvider>
  );
};

export default hot(App);
