import { useGLTF } from "@react-three/drei";
import { useLayoutEffect } from "react";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";
import { MeshProps, useLoader } from "react-three-fiber";
import React from "react";
import { TextureLoader } from 'three/src/loaders/TextureLoader'

type GLTFResult = GLTF & {
  nodes: {
    Plastic_bag_: THREE.Mesh;
  };
  materials: {
    TrashBag_Small1: THREE.MeshStandardMaterial;
  };
};

const PlasticBagModel = React.forwardRef(
  ({ ...props }: MeshProps, ref: React.ForwardedRef<THREE.Group>) => {
    const { scene, materials, nodes } = useGLTF(
      "/3d/bag.glb",
      true
    ) as unknown as GLTFResult;
    const texture = useLoader(TextureLoader, '/images/bagTexture.png')

    const [dialGeometry, setDialGeometry] = React.useState<THREE.Group>();

    useLayoutEffect(() => {
      const s = scene.clone(true);
      setDialGeometry(s);
      scene.traverse(
        (obj) =>
          obj.type === "Mesh" && (obj.receiveShadow = obj.castShadow = true)
      );
      Object.assign(nodes.Plastic_bag_.material, {
        attach: materials.TrashBag_Small1,
      });

      texture.flipY = false;
      // texture.format = THREE.RGFormat;
      texture.format = THREE.RGBAFormat;
      texture.type = THREE.UnsignedShort5551Type;
      Object.assign(materials.TrashBag_Small1, {
        metalness: 0.8,
        roughness: 0.803,
        map: texture,
        color: new THREE.Color(0xffffff),
      });
    }, [materials, scene, nodes, texture]); 

    return (
      <group>
        {dialGeometry && (
          <primitive object={dialGeometry} ref={ref} {...props} />
        )}
      </group>
    );
  }
);

export default PlasticBagModel;
