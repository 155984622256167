import { Suspense, useRef } from "react";
import { Canvas } from "react-three-fiber";
import * as THREE from "three";
import OceanModel, {
  Controls,
  HtmlContentFull,
  HtmlContentLeft,
  HtmlContentRight,
} from "./OceanModel";
import {
  Box,
  Button,
  Center,
  Text,
  SlideFade,
  useDisclosure,
  HStack,
} from "@chakra-ui/react";
import CustomLoader from "./CustomLoader";

export default function PlasticBagViewer() {
  const camera = new THREE.PerspectiveCamera(
    55,
    window.innerWidth / window.innerHeight,
    1,
    20000
  );
  camera.position.set(30, 30, 100);
  const domContent = useRef<HTMLElement>();
  const domContent2 = useRef<HTMLElement>();

  const { isOpen, onToggle } = useDisclosure();
  return (
    <Canvas camera={camera}>
      <Suspense fallback={<CustomLoader />}>
        <OceanModel />
        </Suspense>

      <HtmlContentLeft portal={domContent}>
        <HStack width="100vw" justifyContent="flex-start">
          <Button
            onClick={() => onToggle()}
            sx={{
              color: "white",
              fontWeight: "bold",
              textTransform: "uppercase",
              fontFamily: "Helvetica",
              padding: "8px",
              marginLeft: "-50vw",
            }}
          >
            {isOpen ? "Close" : "About"}
          </Button>
        </HStack>
      </HtmlContentLeft>
      <HtmlContentRight portal={domContent}>
        {!isOpen && (
          <Text
            sx={{
              padding: "8px",
              fontFamily: "Helvetica",
              color: "white",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              Interactions:
            </span>
            <li>W - Control the waves</li>
            <li>B - Add more bags</li>
            <li>R - Remove bags</li>
          </Text>
        )}
      </HtmlContentRight>
      <HtmlContentFull portal={domContent2}>
        <SlideFade in={isOpen}>
          <Box
            color="black"
            shadow="md"
            bg="white"
            opacity="0.8"
            marginLeft="-50vw"
            marginTop="-45vh"
            width="calc(100vw - 2px)"
            height="calc(100vh - 20px)"
          >
            <Center>
              <Text
                marginTop="200px"
                width="800px"
                fontFamily="Helvetica"
                fontSize="20px"
              >
                Escape the object-overload reality and enter this space.
                <br />
                <span style={{ fontStyle: "italic" }}>
                  ‘This Plastic Will Never Disappear’
                </span>{" "}
                is a simulation of a world existing in the cloud. The plastic
                bag will never disappear from the cloud. It will live here forever. Until we
                die.
                <br /> <br />
                Is beauty just a question of looks, or could there be more to it
                than meets the eye? The plastic bag symbolises an ordinary
                object forgotten in a world of things.
                <br />
                Take time. Look at it. In this world of nothing, this bag is
                everything. We don't know what will happen after our life, for the bag afterlife is forever.
                <br />
                <br />
                <b>Jon Reistad (2022)</b>
              </Text>
            </Center>
          </Box>
        </SlideFade>
      </HtmlContentFull>
      <Controls />
      {/* <OrbitControls  /> */}
    </Canvas>
  );
}
