import React, {
  useRef,
  useMemo,
  useCallback,
  useLayoutEffect,
  useEffect,
  useState,
} from "react";
import { extend, useThree, useLoader, useFrame } from "@react-three/fiber";
import * as THREE from "three";

import { Water } from "three/examples/jsm/objects/Water.js";
import { Sky, Html, OrbitControls, Environment } from "@react-three/drei";
import PlasticBagModel from "./PlasticBagModel";
import { Euler } from "three";
import {  randomNum } from "../utils";

extend({ Water });

const KEYS = {
  W: 87,
  ArrowUp: 38,
  ArrowDown: 40,
  ArrowLeft: 37,
  ArrowRight: 39,
  B: 66,
  R: 82,
};


export const Controls = () => {
  const controls = useRef();
  const { camera, gl } = useThree();
  useFrame(() => {
    controls?.current.update();
  });
  useEffect(() => {
    controls.current.addEventListener("change", () => {});
  }, []);

  return (
    <OrbitControls
      ref={controls}
      args={[camera, gl.domElement]}
      minZoom={10}
      minPolarAngle={1.2}
      maxPolarAngle={1.5}
      maxDistance={200}
    />
  );
};

function getWaveInfo(x, z, time, waves) {
  const pos = new THREE.Vector3();
  const tangent = new THREE.Vector3(1, 0, 0);
  const binormal = new THREE.Vector3(0, 0, 1);
  Object.keys(waves).forEach((wave) => {
    const w = waves[wave];
    const k = (Math.PI * 2) / w.wavelength;
    const c = Math.sqrt(9.8 / k);
    const d = new THREE.Vector2(
      Math.sin((w.direction * Math.PI) / 180),
      -Math.cos((w.direction * Math.PI) / 180)
    );
    const f = k * (d.dot(new THREE.Vector2(x, z)) - c * time);
    const a = w.steepness / k;

    pos.x += d.y * (a * Math.cos(f));
    pos.y += a * Math.sin(f);
    pos.z += d.x * (a * Math.cos(f));

    tangent.x += -d.x * d.x * (w.steepness * Math.sin(f));
    tangent.y += d.x * (w.steepness * Math.cos(f));
    tangent.z += -d.x * d.y * (w.steepness * Math.sin(f));

    binormal.x += -d.x * d.y * (w.steepness * Math.sin(f));
    binormal.y += d.y * (w.steepness * Math.cos(f));
    binormal.z += -d.y * d.y * (w.steepness * Math.sin(f));
  });

  const normal = binormal.cross(tangent).normalize();

  return { position: pos, normal: normal };
}

export function HtmlContentLeft({
  className = "",
  style = {},
  children,
  portal,
}) {
  const { size } = useThree();
  return (
    <Html
      portal={portal}
      style={{
        // position: "absolute",
        top: -size.height / 2,
        width:  size.width,
      }}
    >
      <div className={className} style={style}>
        {children}
      </div>
    </Html>
  );
}

export function HtmlContentRight({
  className = "",
  style = {},
  children,
  portal,
}) {
  const { size } = useThree();
  return (
    <Html
      portal={portal}
      style={{
        top: -size.height / 2,
        width:  size.width / 2,
        display: 'flex',
        justifyContent:'right',
      }}
    >
      <div className={className} style={style}>
        {children}
      </div>
    </Html>
  );
}

export function HtmlContentFull({
  className = "",
  style = {},
  children,
  portal,
}) {
  return (
    <Html
      portal={portal}
      style={{
        // position: 'absolute',
        width: "calc(100% - 2px)",
        height: "calc(100% - 50px)",
      }}
    >
      <div className={className} style={style}>
        {children}
      </div>
    </Html>
  );
}

const PlasticBagWithWaves = ({  waves, timeValue, position }) => {
  const bagRef = useRef();

  function updateBoxes(delta) {
    const t = timeValue;
    const b = bagRef.current;
    const waveInfo = getWaveInfo(b.position.x, b.position.z, t, waves);
    b.rotation.x = -waveInfo.normal.x * 1.8;
    b.position.y = waveInfo.position.y - 3;
    const quat = new THREE.Quaternion().setFromEuler(
      new THREE.Euler(waveInfo.normal.x, waveInfo.normal.y, waveInfo.normal.z)
    );
    b.quaternion.rotateTowards(quat, delta * 0.9);
  }

  useFrame((state, delta) => {
    const bag = bagRef.current;
    if (bag) {
      updateBoxes(delta);
    }
  });
  return (
    <PlasticBagModel
      scale={20}
      rotation={new Euler(-Math.PI / 5, 0, 0)}
      ref={bagRef}
      position={position}
    />
  );
};

function OceanModel() {
  const ref = useRef();

  const [inclination, setInclination] = React.useState(0.49);

  const [timeValue, setTimeValue] = React.useState(0);
  const [bags, setBags] = React.useState([
    { position: new THREE.Vector3(0, 0, 0) },
  ]);
  const [waves, setWaves] = useState({
    A: { direction: 0, steepness: 0.1, wavelength: 60 },
    B: { direction: 30, steepness: 0.4, wavelength: 30 },
    C: { direction: 60, steepness: 0.2, wavelength: 205 },
  });
  const gl = useThree((state) => state.gl);
  const waterNormals = useLoader(
    THREE.TextureLoader,
    "https://raw.githubusercontent.com/mrdoob/three.js/master/examples/textures/waternormals.jpg"
  );

  useLayoutEffect(() => {
    ref.current.traverse(
      (obj) =>
        obj.type === "Mesh" && (obj.receiveShadow = obj.castShadow = true)
    );
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", onDocumentKeyDown, true);
    function onDocumentKeyDown(event) {
      const keyCode = event.which;
      if (keyCode === KEYS.W) {

        setWaves((prev) => ({
          A: {
            direction: Math.random() * 360,
            steepness: Math.random() * 0.5,
            wavelength: Math.random() * 200,
          },
          B: {
            direction: Math.random() * 360,
            steepness: Math.random() * 0.2,
            wavelength: Math.random() * 200,
          },
          C: {
            direction: Math.random() * 360,
            steepness: Math.random() * 0.4,
            wavelength: Math.random() * 170,
          },
        }));
      }
      if (keyCode === KEYS.B) {
        setBags((prev) => {
            const newBags = [...prev];
            const position = {
              x: randomNum(-100, 200),
              y: randomNum(0, 200),
              z: randomNum(-100, 200),
            };
            newBags.push({
              position: new THREE.Vector3(position.x, position.y, position.z),
            });
            return newBags;
        });
      }
      if(keyCode === KEYS.R){
        setBags(prev => {
          const newArr = [...prev]
          newArr.pop();
          return newArr;
        })
      }
    }
  }, []);

  waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping;
  const geom = useMemo(
    () => new THREE.PlaneGeometry(10000, 10000, 512, 512),
    []
  );
  const waterConfig = useMemo(
    () => ({
      textureWidth: 512,
      textureHeight: 512,
      waterNormals,
      sunDirection: new THREE.Vector3(),
      sunColor: 0xffffff,
      waterColor: 0x001e0f,
      distortionScale: 3.7,
      fog: false,
      format: gl.encoding,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [waterNormals]
  );

  useFrame((_, delta) => {
    ref.current.material.uniforms.time.value += delta;
    setTimeValue(ref.current.material.uniforms.time.value);
  });

  // ANIMATE SUN
  useFrame(() => {
    setInclination((a) => a + 0.001);
  });

  useFrame((state, delta) => {
    onBC();
  });

  const onBC = useCallback(() => {
    const shader = ref.current.material;
    shader.uniforms.waveA = {
      value: [
        Math.sin((waves.A.direction * Math.PI) / 180),
        Math.cos((waves.A.direction * Math.PI) / 180),
        waves.A.steepness,
        waves.A.wavelength,
      ],
    };
    shader.uniforms.waveB = {
      value: [
        Math.sin((waves.B.direction * Math.PI) / 180),
        Math.cos((waves.B.direction * Math.PI) / 180),
        waves.B.steepness,
        waves.B.wavelength,
      ],
    };
    shader.uniforms.waveC = {
      value: [
        Math.sin((waves.C.direction * Math.PI) / 180),
        Math.cos((waves.C.direction * Math.PI) / 180),
        waves.C.steepness,
        waves.C.wavelength,
      ],
    };
    shader.vertexShader = document.getElementById("vertexShader").textContent;
    shader.fragmentShader =
      document.getElementById("fragmentShader").textContent;
  }, [waves]);

  return (
      <group>
      <Sky
      />
      <group>

        <ambientLight intensity={0.3} />
        <directionalLight intensity={0.5}  />
        <directionalLight intensity={0.5}  />
        <directionalLight intensity={0.5}  />
        <directionalLight intensity={0.5}  />

        {bags.map(({ position }, index) => (
          <PlasticBagWithWaves
            waves={waves}
            position={position}
            key={index}
            timeValue={timeValue}
          />
        ))}

      </group>
      <water
        ref={ref}
        args={[geom, waterConfig]}
        rotation-x={-Math.PI / 2}
        position={[0, 0, 0]}
      />
      </group>
  );
}

export default OceanModel;
